body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: rgb(10, 10, 10);
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
/* * {
  border: 1px solid red !important;
} */
