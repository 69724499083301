.button_wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ce3c32;
  width: 140px;
  height: 40px;
  border-radius: 8px;

  transition: ease 0.3s;
}

.active {
  background-color: rgb(41, 41, 41);
}

.button_wrapper:hover {
  transform: scale(1.05);
  transition: ease 0.3s;
  cursor: pointer;
}

p {
  font-size: 18px;
  color: white;
  font-weight: normal;
}
