.card_container {
  display: flex;
  justify-content: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.card_container img {
  border-radius: 9px;
  height: auto;
  max-width: 200px;
  padding: 1px;
  transition: transform 0.3s ease;
}

.card_container:hover {
  transform: scale(1.05);
}

.card_container:hover img {
  transform: scale(1.02);
}
