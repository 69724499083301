h2 {
  text-align: center;
  color: white;
}

.pack_cards_container {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
  color: white;
  margin: 0 auto;
  transition: heigh 0.3s ease;
}

.pack_card_name {
  text-align: center;
}

.pack_card_item_opening :hover {
  cursor: -webkit-grab;
}

.pack_card_item_opening .card_container {
  pointer-events: none;
}

.pack_card_item_opening .card_container img {
  margin-top: 100px;
  transform: scale(1.6);
}

.card_swiper {
  background: none;
  border: none;
}

.opening_container {
  display: flex;
  justify-content: center;
}

.card_opening_help_text {
  pointer-events: none;
  display: flex;
  margin-top: 500px;
}

/* Animations for pack opening */
.pack_card_item_opening {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: absolute;
}

.slide_out_left {
  transform: translateX(-100%);
  opacity: 0;
}

.slide_in_right {
  transform: translateX(100%);
  opacity: 0;
}

.show_card {
  transform: translateX(0);
  opacity: 1;
}
.fade_in {
  animation: fadeIn 1s ease forwards;
}

.fade_out {
  animation: fadeOut 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
