.navbar_item {
  color: white;
  display: inline;
  list-style: none;
  font-weight: 300;
  margin-right: 20px;
  font-size: 24px;
  cursor: pointer;
  text-decoration: none;
}
