.pack_display {
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.pack_image {
  border-radius: 3%;
  position: relative;
  transition: ease 0.9s;
  display: inline-block;
  max-width: 250px;
  height: auto;
}

.pack1 {
  transform: rotateZ(-12deg);
  z-index: 1;
  padding-top: 10px;
  margin-right: -120px;
}

.pack2 {
  transform: rotateZ(2deg);
  z-index: 2;
}

.pack3 {
  transform: rotateZ(14deg);
  z-index: 1;
  padding-top: 45px;
  margin-left: -120px;
}

.pack1:hover {
  z-index: 10;
  transform: scale(1.1) rotateZ(-14deg);
  transition: ease 0.3s;
}
.pack2:hover {
  z-index: 10;
  /* transform: rotateZ(0); */
  transform: scale(1.1) rotateZ(4deg);
  transition: ease 0.3s;
}
.pack3:hover {
  z-index: 10;
  transform: scale(1.1) rotateZ(16deg);
  transition: ease 0.3s;
}

.pack1:hover:active {
  z-index: 10;
  transform: scale(1.1) rotateZ(-14deg) rotateX(25deg);
  transition: ease 0.3s;
}
.pack2:hover:active {
  z-index: 10;
  transform: scale(1.1) rotateZ(4deg) rotateX(25deg);
  transition: ease 0.3s;
}
.pack3:hover:active {
  z-index: 10;
  transform: scale(1.1) rotateZ(16deg) rotateX(25deg);
  transition: ease 0.3s;
}
