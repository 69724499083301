.navbar {
  background-color: #ce3c32;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  position: sticky;
  z-index: 10;
  margin-bottom: 50px;
  box-shadow: #000400 0px 10px 30px -10px;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
    height: auto;
    padding: 10px 0;
  }
}
.navbar_logo {
  height: auto;
  width: 70px;
  margin-left: 20px;
}

.navbar_container {
  width: 90% !important;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;
}

.navbar_menu {
  margin-right: 48px;
  display: flex;
  align-items: center;
}
