.home_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: center;
}

.home_text {
  display: flex;
  justify-content: center;
  text-align: center;
}

.home_text p {
  font-weight: bold;
  max-width: 800px;
  font-size: 64px;
}

.home_button {
  display: flex;
  justify-content: center;
  transform: scale(1.5);
}
