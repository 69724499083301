.card_details_wrapper {
  height: 100vh;
}

.card_details_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 60%;
  margin: 0 auto;
}

.card_details_container img {
  border-radius: 3%;
  max-width: 100%;
  height: auto;
}

.card_detail_text {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

h2,
img,
.card_detail_text {
  margin-bottom: 10px;
}
