.cards_section_wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
}

.cards_section_container {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card_filter_buttons {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: -150px;
}
.card_filter_buttons p {
  text-align: center;
}
