.section_wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  color: white;
  margin-top: 100px;
  scroll-snap-align: center;
}
.section_container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.section_gif {
  height: auto;
  width: 1000px;
  border-radius: 20px;
  box-shadow: rgba(69, 69, 69, 0.25) 0px 0px 10px 0px;
}

.section_info {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.section_text {
  background-color: peru;
}

.section_title {
  font-weight: 700;
  font-size: 42px;
  margin-left: -15px;
}
